import React from "react";
import EarnHero from "../components/EarnComp/EarnHero";
import { Card, Col, Container, Row } from "react-bootstrap";
import Section1 from "../components/EarnComp/Section1";
import CEX from "../components/CEX/CEX";
import Section4 from "../components/Section4";

const data2 = [
  {
    heading: "Why Choose Buybitz EU Earn?",
    text: "High Yield Earnings: Earn up to 12% annual interest on your USDT and USDC holdings, significantly outperforming traditional savings accounts and many other investment options.",
  },
  {
    heading: "Safety First",
    text: "Rest easy knowing that your investments are managed by a team of experienced crypto professionals who employ safe and proven strategies to maximize your earnings while minimizing risks.",
  },
  {
    heading: "Stability and Transparency",
    text: "Our investment strategies are designed to prioritize stability and transparency. We provide regular updates on your earnings and the strategies being employed so that you can make informed decisions.",
  },
  {
    heading: "Hassle-Free",
    text: "Say goodbye to complicated trading decisions and technicalities. We handle all the work, so you can enjoy passive income without the stress.    ",
  },
  {
    heading: "Flexibility",
    text: "Deposit and withdraw funds at your convenience. There are no lock-in periods, and you maintain full control over your asset",
  },
];
const Earn = () => {
  return (
    <>
      <EarnHero />
      <div className="w-full py-10 bg-[#4700FB]">
        <Container>
          <p className="text-xl my-auto  text-[#ffffffcc]">
            For a limited time only, crypto lending with selected assets now
            offers an increased yield of up to{" "}
            <span className="font-bold text-white">6.00%</span> APY until
            October 15th, 23:59 UTC.
          </p>
        </Container>
      </div>
      <Section1 />
      <CEX
        data={data2}
        heading="Buybitz EU Earn: Maximize Your Earnings with Confidence"
        text="Look no further than Crypto Earn, your gateway to earning up to 12% annual interest on USDT and USDC. With Buybitz EU  Earn, you can put your idle assets to work and watch your savings grow, all while resting assured that your investments are being managed by seasoned crypto professionals."
      />
      <Section4 />
    </>
  );
};

export default Earn;
