import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import earnpng from "../../assets/Earn/OBJECTS.png";
import img1 from "../../assets/2.png";
import img2 from "../../assets/3.png";
import star from "../../assets/Star 3.png";
import bg from "../../assets/Line elements.svg";
import { FaApple, FaGooglePlay } from "react-icons/fa";

const EarnHero = () => {
  return (
    <div className="relative min-h-screen w-full z-0 bg-[#090622] py-20 flex flex-col justify-center overflow-hidden">
      <img
        loading="eager"
        src={bg}
        className="absolute top-0 left-0 right-0 bottom-0 w-full object-cover h-full z-[-1] opacity-20"
        alt=""
      />
      <Container className="flex flex-col items-center">
        <Row className="g-5">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="flex flex-col justify-center items-center lg:items-start">
            <div className="flex gap-[10px] w-fit py-3 px-6 rounded-md bg-[#1E1B36] items-center justify-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none">
                <path
                  d="M18 8.16497H11.0158L15.9542 3.22657L14.7734 2.04583L9.83503 6.98423V0H8.16497V6.98423L3.22657 2.04583L2.04583 3.22657L6.98423 8.16497H0V9.83503H6.98423L2.04583 14.7734L3.22657 15.9542L8.16497 11.0158V18H9.83503V11.0158L14.7734 15.9542L15.9542 14.7734L11.0158 9.83503H18V8.16497Z"
                  fill="white"
                />
              </svg>{" "}
              <p className="my-auto text-white text-lg">Crypto Exchange</p>
            </div>
            <div className="w-full  font-sans text-white mb-4">
              <h1 className="text-[62px]  font-bold mb-4 ">
                Buybitz EU Earn - Crypto Lending
              </h1>
              <p className="text-[#DAD8EB] text-xl">
                The transparent and secure way to generate yield. The
                transparent and secure way to generate yield
              </p>
            </div>
            <a href="https://app.buybitz.com/signup" target="_blank">
              <button className="w-[166px] h-[70px] bg-[#4700FB] rounded-xl text-white font-semibold">
                Get Started
              </button>
            </a>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className="flex flex-col justify-center items-center lg:items-start">
            <div className=" flex gap-2 min-h-[350px] md:min-h-[620px] z-0">
              <img
                loading="eager"
                src={star}
                className="absolute left-0 top-[5%]"
                alt=""
              />
              {/* <img
                loading="eager"
                src={img1}
                className="h-[300px] md:h-[550px] mt-auto"
                alt=""
              /> */}
              <img
                loading="eager"
                src={earnpng}
                className="h-[300px] md:h-[569px] mb-auto"
                alt=""
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EarnHero;
