import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
const SubFooter = () => {
  useEffect(() => {
    const widgetContainer = document.getElementById("coinmarket-widget");
    const widget = document.getElementById("coinmarketcap-widget-marquee");
    widgetContainer.appendChild(widget);
  }, []);

  return (
    <div className="min-h-fit flex items-center bg-[#090622] text-white border-none overflow-hidden">
      <div id="coinmarket-widget" />
    </div>
  );
};

export default SubFooter;
