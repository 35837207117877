import React from "react";
import icon from "../../assets/Earn/image 73.png";
import { AiOutlineDown } from "react-icons/ai";
import { Card, Col, Container, Row } from "react-bootstrap";

const Section1 = () => {
  return (
    <div className="w-full py-20 bg-[#090622] text-white">
      <Container>
        <p className="font-bold text-[36px] md:text-[56px] text-center mb-4 ">
          Calculate Your Rewards
        </p>
        <p className="text-[16px] text-[#DAD8EB] text-center my-auto  ">
          Select a token, amount, CRO lockup and term to calculate your rewards
          *
        </p>
        <div className="mt-16 w-full 2xl:w-[1034px] rounded-[20px] bg-[#1E1B36] flex justify-center items-center mx-auto py-4 ps-5 ">
          <Row className="g-4 w-full mx-auto ">
            <Col xs={6} sm={6} lg={2} className="flex items-center">
              <div className="  pe-3 my-auto w-full">
                <p className="text-xl my-auto mb-3">TOKEN</p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-1">
                    <img src={icon} className="h-[22px] w-[22px] " alt="" />
                    <p className="my-auto text-2xl font-semibold">USDC</p>
                  </div>
                  <AiOutlineDown size={20} />
                </div>
              </div>
            </Col>
            <Col
              xs={6}
              sm={6}
              lg={3}
              className=" border-x border-gray-600 flex items-center">
              <div className="ps-3 my-auto w-full ">
                <p className="text-xl my-auto mb-3">AMOUNT (USD)</p>
                <div className="flex items-center justify-between">
                  <p className="text-2xl font-semibold my-auto">$ 5000</p>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} lg={4} className="border-r border-gray-600">
              <div className="px-3 my-auto w-full ">
                <p className="text-xl my-auto mb-3">CRO LOCKUP</p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-1">
                    <p className="my-auto text-2xl font-semibold">
                      $ 4,000 to 40,000
                    </p>
                  </div>
                  <div className="flex items-center gap-1">
                    <p className="my-auto text-[#ffffffcc]">USD</p>
                    <AiOutlineDown size={20} />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={6} sm={6} lg={3}>
              <div className="ps-3 my-auto w-full ">
                <p className="text-xl my-auto mb-3">TERM</p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-1">
                    <p className="my-auto text-2xl font-semibold">12 Months</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Section1;
