import "./App.css";
import ReactDOM from "react-dom/client";
import { Suspense, lazy, useEffect, useState } from "react";
import "../src/fonts/fonts.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import SubFooter from "./components/SubFooter";
import Earn from "./pages/Earn";
const Swap = lazy(() => import("./pages/Swap"));
const Future = lazy(() => import("./pages/Future"));
const Home = lazy(() => import("./pages/Home"));
const MobileFooter = lazy(() => import("./components/MobileFooter"));
const NewFooter = lazy(() => import("./components/NewFooter"));
const Career = lazy(() => import("./pages/Career"));
const OTC = lazy(() => import("./pages/OTC"));
const OurPPolicy = lazy(() => import("./pages/OurPPolicy"));
const NavBarComp = lazy(() => import("./components/NavBarComp"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Tc = lazy(() => import("./pages/Tc"));
const RiskDisc = lazy(() => import("./pages/RiskDisc"));
const Cookies = lazy(() => import("./pages/Cookies"));
const UserAgre = lazy(() => import("./pages/UserAgre"));
const OTCPolicy = lazy(() => import("./pages/OTCPolicy"));
const Law = lazy(() => import("./pages/Law"));
const Fees = lazy(() => import("./pages/Fees"));
const Help = lazy(() => import("./pages/Help"));
const HowToCreateAccount = lazy(() => import("./pages/HowToCreateAccount"));
const KYCVerification = lazy(() => import("./pages/KYCVerification"));
const HowToWidthrawFiat = lazy(() => import("./pages/HowToWidthrawFiat"));
const HowToDeposit = lazy(() => import("./pages/HowToDeposit"));
const ManageMyAccount = lazy(() => import("./pages/ManageMyAccount"));
const Faq = lazy(() => import("./pages/IDVerification/Faq"));
const EmailVerification = lazy(() =>
  import("./pages/IDVerification/EmailVerification")
);
const PhotoId = lazy(() => import("./pages/IDVerification/PhotoId"));
const PanVerification = lazy(() =>
  import("./pages/IDVerification/PanVerification")
);
const Crypto = lazy(() => import("./pages/CryptoPage/Crypto"));

function App() {
  // const [isMobile, setIsMobile] = useState(false);
  // useEffect(() => {
  //   const date = new Date();
  //   console.log(date.toLocaleString());
  //   window.innerWidth <= 1000 ? setIsMobile(true) : setIsMobile(false);
  //   const resize = window.addEventListener("resize", () => {
  //     window.innerWidth <= 1000 ? setIsMobile(true) : setIsMobile(false);
  //   });

  //   return () => {
  //     window.removeEventListener("resize", resize);
  //   };
  // }, []);
  const cyrptomar = document.getElementById("crypto");
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingPage />}>
        <div className="min-h-fit py-2 flex items-center bg-[#090622] text-white border-none overflow-hidden">
          <marquee behavior="" direction="">
            Don’t invest unless you’re prepared to lose all the money you
            invest. This is a high-risk investment and you should not expect to
            be protected if something goes wrong.
          </marquee>
        </div>
        <SubFooter />
        <NavBarComp />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/career" element={<Career />} />
          <Route path="/otc" element={<OTC />} />
          <Route path="/privacy" element={<OurPPolicy />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/terms-conditions" element={<Tc />} />
          <Route path="/risk-disclosure" element={<RiskDisc />} />
          <Route path="/cookies" element={<Cookies />} />
          <Route path="/user-agreement" element={<UserAgre />} />
          <Route path="/terms-policy" element={<OTCPolicy />} />
          <Route path="/law" element={<Law />} />
          <Route path="/fees" element={<Fees />} />
          <Route path="/help" element={<Help />} />
          <Route path="/prices" element={<Crypto />} />
          <Route
            path="/how-to-create-account"
            element={<HowToCreateAccount />}
          />
          <Route path="/kyc-verification" element={<KYCVerification />} />
          <Route path="/how-to-withdraw-fiat" element={<HowToWidthrawFiat />} />
          <Route path="/how-to-deposit" element={<HowToDeposit />} />
          <Route path="/manage-my-account" element={<ManageMyAccount />} />
          <Route path="/verification-email" element={<EmailVerification />} />
          <Route path="/photo-id" element={<PhotoId />} />
          <Route path="/pan" element={<PanVerification />} />
          <Route path="/pan" element={<PanVerification />} />
          <Route path="/future" element={<Future />} />
          <Route path="/swap" element={<Swap />} />
          <Route path="/earn" element={<Earn />} />
        </Routes>
        {/* {isMobile ? <MobileFooter /> : <NewFooter />} */}
        <NewFooter />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
